body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
	border: none;
	font-size: 1rem;
	font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	transition: background-color 5000s ease-in-out 0s;
}
input:-internal-autofill-selected {
	color: inherit !important;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	/* padding: 0px !important;
	overflow: auto !important; */
	background-color: #EEEEEE;
	/* color: #3C4858; */
	margin: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}

blockquote footer:before, blockquote small:before {
	content: '\2014 \00A0';
}

blockquote p {
	font-style: italic;
}

body, h1, h2, h3, h4, h5, h6 {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}

a {
	text-decoration: none;
}

a:hover, a:focus {
	text-decoration: none;
}

legend {
	border-bottom: 0;
}

* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}

*:focus {
	outline: 0;
}

a:focus, a:active, button:active, button:focus, button:hover, button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, select::-moz-focus-inner, input[type="file"]>input[type="button"]::-moz-focus-inner {
	outline: 0 !important;
}

legend {
	margin-bottom: 20px;
	font-size: 21px;
}

output {
	padding-top: 8px;
	font-size: 14px;
	line-height: 1.42857;
}

footer {
	padding: 15px 0;
}

footer ul {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}

footer ul li {
	display: inline-block;
}

footer ul li a {
	color: inherit;
	padding: 15px;
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 3px;
	text-decoration: none;
	position: relative;
	display: block;
}

footer ul li a:hover {
	text-decoration: none;
}

@media (max-width: 991px) {
	body, html {
		position: relative;
		overflow-x: hidden;
	}
	#bodyClick {
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 0;
		top: 0;
		left: auto;
		right: 260px;
		content: "";
		z-index: 9999;
		overflow-x: hidden;
	}
}