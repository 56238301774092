body {
	/* padding: 0px !important;
	overflow: auto !important; */
	background-color: #EEEEEE;
	color: #3C4858;
	margin: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}

.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
}

.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
}

.react-grid-item.cssTransforms {
	transition-property: transform;
}

.react-grid-item.resizing {
	z-index: 1;
	will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
	transition: none;
	z-index: 3;
	will-change: transform;
}

.react-grid-item.react-grid-placeholder {
	background: teal;
	opacity: 0.2;
	transition-duration: 100ms;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.react-grid-item>.react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 0;
	right: 0;
	z-index: 9999;
	cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
	content: "";
	position: absolute;
	right: 3px;
	bottom: 3px;
	width: 5px;
	height: 5px;
	border-right: 2px solid rgba(0, 0, 0, 0.4);
	border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}